const devConfig = {
    // baseUrl: 'http://172.16.1.12:8001',
    baseUrl: 'http://192.168.100.74:8001',
    baseWsUrl: 'ws://192.168.100.74:9092'
    // baseUrl: 'https://dash.cncmonitor.com.br/backend',
    // baseWsUrl: 'wss://dash.cncmonitor.com.br/backendws/'
}

const stagingConfig = {
    baseUrl: 'https://teste.apontahora.tv/backend',
    baseWsUrl: 'wss://teste.apontahora.tv/backendws/'
}

// const productionConfig = {
//     baseUrl: 'https://dash.cncmonitor.com.br/backend',
//     baseWsUrl: 'wss://dash.cncmonitor.com.br/backendws/'
// }

// Para a ncam
const productionConfig = {
    baseUrl: 'https://dash.ncam.grvlab.com.br/backend',
    baseWsUrl: 'wss://dash.ncam.grvlab.com.br/backendws/'
}

// Ambiente temporário
// const productionConfig = {
//     baseUrl: 'https://dash-novo.cncmonitor.com.br/backend',
//     baseWsUrl: 'wss://dash-novo.cncmonitor.com.br/backendws/'
// }

// Para a JN
// const productionConfig = {
//     baseUrl: 'http://192.168.0.108:8001',
//     baseWsUrl: 'ws://'+ window.location.hostname +':9092' //Levando em consideração que o WebSocketServer está no mesmo local que o front-end     
// }

function buildConfig(targetConfig) {
    targetConfig.createLink = function (suffix) {
        return this.baseUrl+suffix
    }
    return targetConfig
}

if (process.env.VUE_APP_STAGING === 'staging')
    module.exports = buildConfig(stagingConfig)
else if (process.env.NODE_ENV === 'production') 
    module.exports = buildConfig(productionConfig)
else 
    module.exports = buildConfig(devConfig)
   